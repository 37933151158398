import React from "react";
import { BUTTON_TYPE, ROUTES, SERVICES } from "./constants";
import Contact from "./Contact";
import Banner from "./Banner";
import { Link } from "react-router-dom";

export const LinkButton = ({ name, type, action, classname, btn }) => {
  return (
    <a
      className={`h-11 p-3 text-center ${
        btn === BUTTON_TYPE.SECONDARY
          ? "bg-black text-white"
          : btn === BUTTON_TYPE.PRIMARY
          ? "bg-white text-black border border-black"
          : "bg-white text-sea-blue px-4 py-2 align-center rounded-xl font-bold"
      } ${classname}`}
      href={action || ROUTES.HOME}
      target={type || "_blank"}
    >
      {name}
    </a>
  );
};

const Carousel = () => {
  return (
    <div className="bg-slate-200 bg-no-repeat md:bg-80 bg-center">
      <div className="bg-slate-200 bg-opacity-25 py-6">
        <p className="text-regal-black text-2xl md:text-7xl font-semibold px-6 py-8 w-full whitespace-break-spaces break-words leading-normal">
          PROVIDING HOLISTIC & COMPLETE HOMOEOPATHIC CARE FOR ALL
        </p>
        <div className="mx-6 my-6 md:my-10 grid grid-cols-1 md:grid-cols-4 gap-4">
          <Link
            className={`h-11 p-3 text-center bg-black text-white`}
            to={ROUTES.ABOUT_US}
          >
            About the clinic
          </Link>
        </div>
      </div>
    </div>
  );
};

const ServiceCard = ({ service }) => {
  return (
    <div className="bg-sea-blue text-white flex flex-col justify-between items-center p-6 border border-white rounded-3xl">
      <p className="text-xl text-center mb-2 md:min-h-56 font-semibold">
        {service?.title}
      </p>
      <div className="text-base py-2 text-justify mb-2">
        {service?.description}
      </div>
      {service?.cta?.action ? (
        <LinkButton
          name={service?.cta?.name}
          action={service?.cta?.action}
          classname={"md:mt-2"}
          type={service?.cta?.type}
        />
      ) : null}
    </div>
  );
};

const Services = () => {
  return (
    <div className="w-full py-6 md:py-10 px-3 leading-normal">
      <p className="font-semibold text-2xl md:text-4xl text-center py-4">
        SERVICES
      </p>
      <div className="my-8 grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-12">
        {SERVICES.map((service, index) => {
          return <ServiceCard service={service} key={`service-${index}`} />;
        })}
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <>
      <Carousel />
      <Services />
      <Banner />
      <Contact />
    </>
  );
};

export default Home;
