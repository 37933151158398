import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import clinic from "./clinic.jpg";
const Banner = () => {
  return (
    <div className="h-96 md:h-800 relative">
      <ParallaxProvider>
        <ParallaxBanner
          layers={[{ image: clinic, speed: -40 }]}
          style={{ height: "100%", opacity: "30%" }}
        />
      </ParallaxProvider>
      <div className="absolute inset-0 flex flex-col items-start justify-center ml-4 font-semibold md:text-xl">
        <p className="text-2xl md:text-3xl my-6">OPENING HOURS</p>
        <p className="my-4">Here When You Need Us</p>
        <div className="my-2">
          <p>Monday - Saturday</p>
          <p>5:30pm- 7:30pm</p>
        </div>
        <p className="my-6">Sunday consultation on appointment basis only</p>
      </div>
    </div>
  );
};

export default Banner;
