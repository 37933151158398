import React from "react";
import counselling from "./counselling.png";
import aboutUs from './about_us_bg.jpg'
import { TREATMENTS } from "./constants";

const TreatmentCard = ({ treatment }) => {
  return (
    <div className="flex flex-col p-2 items-center text-center text-black">
      <img src={treatment?.logo} alt="card" className="w-10 h-10" />
      <div>{treatment?.name}</div>
    </div>
  );
};

const AboutUs = () => {
  return (
    <div className="md:px-4 pb-4 md:pb-8 text-xl md:text-base">
      <div className="w-full h-96">
        <img
          src={aboutUs}
          alt="about_us"
          className="object-fill h-full w-full"
        />
      </div>
      <div className="px-2 mt-4 md:my-6">
        <div>
          At Kotian's Homoeopathic clinic, we’re committed to providing you with
          the most exceptional Homoeopathic care in a compassionate and friendly
          atmosphere.<br></br>
          Since the very beginning, we have had the philosophy that our patients
          come first. No matter why you’re visiting us, we provide comprehensive
          and excellent care.<br></br>
          We provide treatment for all acute and chronic complaints within scope
          in the most safe and gentle way possible
        </div>

        <p className="my-2">We provide treatments for : </p>
        <div className="grid grid-cols-2 md:grid-cols-6 gap-2 p-2 mt-4">
          {TREATMENTS.map((treatment, index) => {
            return (
              <TreatmentCard treatment={treatment} key={`treatment-${index}`} />
            );
          })}
        </div>
        <div >
            <img src={counselling} alt='counsel' className="w-full md:h-600 object-contain"/>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
