import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "./logo.png";
import { HEADER_OPTIONS, ROUTES } from "./constants";

const Title = () => {
  return (
    <div className="text-black text-3xl font-semibold h-full">
      <div className="flex flex-col md:flex-row md:flex-row content-center justify-center items-center h:40 md:h-32">
        <div className="w-20 h-20 md:w-24 md:h-24 md:mb-5">
          {" "}
          <Link to={ROUTES.HOME}>
            <img src={Logo} alt="logo"></img>{" "}
          </Link>
        </div>
        <span className="block mx-0 my-auto text-center">
          KOTIAN'S HOMOEOPATHIC CLINIC
        </span>
      </div>
    </div>
  );
};

const HeaderOption = ({ name, link }) => {
  const location = useLocation();
  const activeRoute = location?.pathname === link;
  return (
    <div
      className={`ml-4 hover:underline hover:drop-shadow-md hover:text-sea-blue ${
        activeRoute ? "text-sea-blue underline" : "text-regal-black"
      }`}
    >
      <Link to={link || "/"}>{name}</Link>
    </div>
  );
};

const HeaderTitles = () => {
  return (
    <div className="text-xl md:text-base w-full font-semibold">
      <nav className="w-full flex flex-row px-4 justify-center flex-wrap mt-4 md:mt-0">
        {HEADER_OPTIONS.map((option, index) => {
          return (
            <HeaderOption
              name={option?.title}
              link={option?.link}
              key={`${option?.title}-${index}`}
            />
          );
        })}
      </nav>
    </div>
  );
};

const Header = () => {
  return (
    <div className="flex flex-col w-full pb-4">
      <Title />
      <div className="flex flex-row">
        <HeaderTitles />
      </div>
    </div>
  );
};

export default Header;
