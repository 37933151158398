import React from "react";

const ContactUs = () => {
  return (
    <div className="text-base text-black text-center md:px-6 pb-8 md:pb-16">
      <div className="px-2">
        <h1 className="text-2xl md:text-4xl font-semibold my-4 md:my-8">
          CONTACT US
        </h1>
        <p className="font-semibold">Dr. Shreyank Kotian, BHMS</p>
        <p className="font-normal">Consultant Homoeopathic physician</p>
        <p className="font-semibold break-words my-4">
          Basement, 'Ave Maria Centre' , Opposite Bhagini Samaj, Near Jeppu
          Market, Jeppu, Mangalore, Karnataka 575001, India
        </p>
        <p className="my-4">drshreyank@gmail.com</p>
        <p>+918867684413</p>
      </div>
      <div className="my-6">
        <iframe
          title={"Clinic"}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15559.659744415092!2d74.8502191!3d12.8487705!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba35ba7c475f301%3A0x23875e26baff254b!2sKotian&#39;s%20Homeopathic%20clinic!5e0!3m2!1sen!2sin!4v1698486486687!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

const Contact = () => {
  return (
    <div className="grid grid-cols-1 gap-2">
      <ContactUs />
    </div>
  );
};

export default Contact;
