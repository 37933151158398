import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Home";
import AboutUs from "./AboutUs";
import Page from "./Page";
import Contact from "./Contact";
import { ROUTES } from "./constants";

function App() {
  return (
    <div>
      <Router>
        <Page>
          <Switch>
            <Route exact path={ROUTES.HOME} component={Home} />
            <Route exact path={ROUTES.ABOUT_US} component={AboutUs} />
            <Route exact path={ROUTES.CONTACT} component={Contact} />
          </Switch>
        </Page>
      </Router>
    </div>
  );
}

export default App;
