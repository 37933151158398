import allergy from './allergies.png'
import fatigue from './fatigue.png'
import low_immunity from './low_im.png'
import hyper_ten from './hyper_ten.png'
import gastric from './gastric.png'
import skin from './skin_rash.png'
import joint_pain from './joint_pain.png'
import back_pain from './backpain.png'
import thyroid from './throid.png'
import child_health from './children_health.png'
import lady from './lady.png'
import depression from './anxiety.png'
import hairfall from './hair.png'
import cough from './cough.png'

export const ROUTES = {
HOME: '/',
ABOUT_US: "/about-us",
CONTACT: "/contact"
}

export const HEADER_OPTIONS = [
  {
    title: "Home",
    link: ROUTES.HOME,
  },
  {
    title: "About Us",
    link: ROUTES.ABOUT_US,
  },
  {
    title: "Contact",
    link: ROUTES.CONTACT,
  },
];

export const SERVICES = [
  {
    title: "APPOINTMENTS FOR CONSULTATION",
    description:
      "You may contact the doctor via phone call/ SMS to obtain appointments to have an in-person consultation and treatment for your ailments  through Homoeopathy",
    cta: {
      name: "CALL/SMS",
      action: "tel:+918867684413",
      type:'_self'
    },
  },
  {
    title: "ONLINE CONSULTATION",
    description:
      "Send a detailed Email/ WhatsApp message with your complaints with photos of any investigations or scan reports already available. The doctor will revert back by the earliest via Phone call or Video call (if required).",
    cta: {
      name: "E-MAIL",
      action: "mailto:drshreyank@gmail.com",
    },
  },
  {
    title: "WHATSAPP",
    description:
      "You may also contact the Doctor through WhatsApp messaging to obtain appointments for online consultation or in-person consultation by pressing the below Whatsapp button",
    cta: {
      name: 'WHATSAPP',
      action: "https://api.whatsapp.com/send?phone=918867684413"
    },
  },
];

export const BUTTON_TYPE = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};

export const TREATMENTS = [
  {
    name: 'Allergies',
    logo: allergy
  },
  {
    name: 'Low immunity',
    logo: low_immunity
  },{
    name: 'Weakness and fatigue',
    logo: fatigue
  },{
    name: 'Lifestyle disorders like hypertension and diabetes',
    logo: hyper_ten
  },{
    name: 'Gastric complaints',
    logo: gastric
  },{
    name: 'Skin diseases',
    logo: skin
  },
  {
    name: 'Joint pains',
    logo: joint_pain
  },{
    name: 'Back pains',
    logo: back_pain
  },{
    name: 'Thyroid complaints',
    logo: thyroid
  },{
    name: 'Children health',
    logo: child_health
  },{
    name: 'Menstrual disorders',
    logo: lady
  },{
    name: 'Depression, Stress, Anxiety',
    logo: depression
  },{
    name: 'Hair fall and dandruff',
    logo: hairfall
  },{
    name: 'Breathlessness and cough',
    logo: cough
  }
]
