import React from 'react'
import upArrow from './up_arrow.png'

const Footer = () => {
    return (
        <div className='text-base text-center py-4 px-4 bg-slate-100 relative'>
        <div>
            <p className='text-xl font-semibold my-4'>Kotian's Homoeopathic Clinic</p>
            <p>drshreyank@gmail.com</p>
            <p>+918867684413</p>
            <p>©2023 by Kotian's Homoeopathic Clinic</p>
        </div>
        <div className='block absolute bottom-14 right-2.5 bg-slate-100 md:hidden' onClick={() => window.scrollTo(0,0)}>
            <img src={upArrow} alt='scroll_to_top' className='w-12 h-12 object-fill'/>
        </div>
        </div>
    )
}
export default Footer
